import React, { useContext, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { AssignTutorContext } from "./AssignTutorModal";

const SearchTutors = () => {
  const [name, setName] = useState("");
  const { setLastName } = useContext(AssignTutorContext);

  const onSubmit = (e) => {
    e.preventDefault();
    setLastName(name);
  };

  return (
    <form onSubmit={onSubmit} data-testid="admin-sub-session-request-search-tutors-form">
      <div className="mt-6 flex space-x-4">
        <div className="min-w-0 flex-1">
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="search"
              name="q"
              id="q"
              autoComplete="off"
              className="pl-10 focus:ring-brand-500 focus:border-brand-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by last name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <button
          type="submit"
          className="cursor-pointer py-0 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchTutors;
