import cx from "classnames";
import noop from "lodash/noop";
import { twMerge } from "tailwind-merge";
import React, { useRef } from "react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon, InformationCircleIcon } from "@heroicons/react/20/solid";

import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";

const ChangeField = ({ isInvalid = false, label, prevValue, showClipboard, value, details }) => {
  const valueRef = useRef();
  const onValueFocus = (event) => {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNode(event.target);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  const onLabelClick = () => {
    valueRef.current?.focus();
  };

  return (
    <div className="inline-flex items-stretch bg-zinc-100 rounded-full h-[24px]">
      <div
        className="cursor-pointer text-xs text-zinc-500/80 pl-3.5 pr-1 self-stretch flex items-center hover:text-zinc-500"
        onClick={isInvalid ? noop : onLabelClick}
      >
        <span>{label}</span>
      </div>
      <div
        className={twMerge(
          cx("bg-white rounded-full m-[1px] px-3.5 flex items-center shadow", {
            "bg-red-50 shadow-": isInvalid,
          })
        )}
      >
        {prevValue && prevValue !== value ? (
          <div className="bg-transparent border-none text-xs text-zinc-400/70 p-0 m-0 line-through flex items-center mr-1.5 hover:no-underline hover:text-zinc-400">
            {prevValue}
            <ArrowRightIcon className="w-3 h-3 ml-1.5 text-zinc-400" />
          </div>
        ) : null}
        <div
          className={twMerge(
            cx("bg-transparent border-none text-xs text-zinc-500 p-0 m-0 font-medium", {
              "text-red-500": isInvalid,
            })
          )}
          onFocus={isInvalid ? noop : onValueFocus}
          ref={valueRef}
          tabIndex={0}
        >
          {value}
        </div>
        {showClipboard ? (
          <CopyToClipboardButton className="p-1 text-zinc-400 hover:text-zinc-600" text={value}>
            <ClipboardDocumentIcon className="w-3.5 h-3.5" />
          </CopyToClipboardButton>
        ) : null}
        {details ? (
          <Tooltip>
            <TooltipTrigger>
              <button
                aria-label="Details"
                className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                type="button"
              >
                <InformationCircleIcon className="w-full h-full" />
              </button>
            </TooltipTrigger>
            <TooltipContent>{details}</TooltipContent>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default ChangeField;
