import debounce from "lodash/debounce";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import TutorTimeOffRequestsContext from "./context";

const SearchForm = () => {
  const { setSearchTerm } = useContext(TutorTimeOffRequestsContext);
  const onChange = useCallback(
    (event) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm]
  );
  const debounced = useMemo(() => {
    return debounce(onChange, 340);
  }, [onChange]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return (
    <div className="flex items-center">
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          className="pl-10 py-[5px] pr-1 text-sm focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm border-gray-300 rounded-lg w-[240px]"
          onChange={debounced}
          placeholder="Search by tutor name"
          type="search"
        />
      </div>
    </div>
  );
};

export default SearchForm;
