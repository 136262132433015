import cx from "classnames";
import { format } from "date-fns";
import React from "react";
import { CalendarIcon } from "@heroicons/react/20/solid";

import { DAY_WITH_SHORT_MONTH, cancellationReason } from "../../constants";

const eventTitleToNiceNameMap = {
  [cancellationReason.NO_PROGRAM]: "No program",
  [cancellationReason.LAST_MINUTE]: "Cancellation",
  [cancellationReason.TIME_OFF]: "Time off",
};

export default ({
  classroomName,
  date,
  gradeLevels,
  reason,
  schoolName,
  schoolSystemName,
  times,
}) => {
  const title = eventTitleToNiceNameMap[reason];

  return (
    <div className="min-w-[320px]">
      <div className="px-5 pt-3 pb-5">
        <div className="border-b pb-4">
          <h4 className={cx("font-medium text-zinc-700 mb-2")}>{title}</h4>
          <ul className="flex items-center text-xs text-zinc-500">
            <li className="flex items-center">
              <CalendarIcon className="w-3.5 h-3.5 mr-1" />
              <span>{format(date, DAY_WITH_SHORT_MONTH)}</span>
            </li>
          </ul>
        </div>
        <dl className="text-xs text-zinc-500 pt-4">
          <dt className="font-semibold mb-1">School system</dt>
          <dd className="mb-3">{schoolSystemName || "All school systems"}</dd>
          <dt className="font-semibold mb-1">School</dt>
          <dd className="mb-3">{schoolName || "All schools"}</dd>
          <dt className="font-semibold mb-1">Grade levels</dt>
          <dd className="mb-3">
            {gradeLevels.length > 0 ? gradeLevels.join(", ") : "All grade levels"}
          </dd>
          {classroomName
            ? [
                <dt className="font-semibold mb-1" key="key">
                  Classroom
                </dt>,
                <dd className="mb-3" key="value">
                  {classroomName}
                </dd>,
              ]
            : null}
          <dt className="font-semibold mb-1">Times</dt>
          <dd className="mb-1">{times.length > 0 ? times.join(", ") : "All day"}</dd>
        </dl>
      </div>
    </div>
  );
};
